<template>
    <div>
        <adam-header-bar
            :userName.prop="user.name"
            :emailAddress.prop="user.email"
            :show-notifications.prop="false"
            :class="[
                { 'bg-dev': isDevelopmentEnv },
                { 'bg-uat': isUatEnv },
            ]"
            @apps-click="clickDrawer"
        >
            <span slot="headerContent" class="app-logo">
                <a href="/">
                    <img
                        class="app-logo-img"
                        src="~adam.ui-core/assets/brand/logo-coode-white.svg"
                    >
                </a>
            </span>
            <span slot="headerContent">
                <span class="mr-1"><h4>{{ $t('metadataTitle') }}</h4></span>
            </span>
            <span slot="profileDetails">
                <div class="flex-col profile-container">
                    <div class="profile-details">
                        <div class="flex-col">
                            <button type="button" @click="copyToken()" class="copy-token-button mb-1">
                                {{ $t('copyToken') }}
                                <font-awesome-icon icon="copy" class="ml-1" />
                            </button>
                            <input type="text" class="copy-text" id="copyText">
                        </div>
                        <div class="pb-1">
                            <a :href="swaggerURL" target="_blank" class="swagger-link">
                                {{ $t('openSwagger') }}
                            </a>
                        </div>
                        <div class="flex-col flex-3 pb-1">
                            <div class="version">{{ $t('version') }}&nbsp;{{ version }}</div>
                            <div class="version ">{{ $t('apiUrl') }}&nbsp;
                                <a :href="apiUrl" class="swagger-link">
                                    {{ apiUrl }}
                                </a>
                            </div>
                        </div>
                        <pui-button state="secondary" icon="report" class="profile-action-button" @click="sendFeedback()">
                            {{ $t('sendFeedback') }}
                        </pui-button>
                        <pui-button
                            state="secondary"
                            icon="warning"
                            class="profile-action-button profile-action-button--important"
                            @click="reportIncident()"
                        >
                            {{ $t('reportIncident') }}
                        </pui-button>
                        <pui-button icon="power" class="profile-action-button" @click="logOut()">
                            {{ $t('signOut') }}
                        </pui-button>
                    </div>

                </div>
            </span>
        </adam-header-bar>
        <pui-navigation-toolbar
            :link-items="navItems"
            :action-items="[]"
            @changed:active-route="newRoute => this.$router.push(newRoute.href)"
        />
        <app-drawer
            ref="appDrawer"
            :data.prop="applications"
            :labels.prop="getAppDrawerLabels"
            :on-click.prop="handleAppClick"
        >
            <span slot="appLogo" class="app-logo">
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg">
            </span>
        </app-drawer>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { AppService } from '@/services';
import { Application } from '@/models';
import { EventBus } from '@/utils';
import { i18n } from '@/i18n-instance-file';
import { sdk } from '@/utils/sdk';
import { Environments } from '@coode/fe-sdk';

@Component({
    name: 'header-wrapper',
})
export default class HeaderWrapper extends Vue {
    private appService: AppService = new AppService();
    private applications: MenuEntry[] = [];
    private navItems: any[] = [];
    private user: {
        name: string | undefined;
        email: string | undefined;
    } = {
        name: '',
        email: '',
    };
    private version: string | undefined = process.env.VUE_APP_VERSION;
    private apiUrl: string | undefined = process.env.VUE_APP_API_BASE_URL;
    private swaggerURL: string | undefined = process.env.VUE_APP_API_SWAGGER_URL;
    private scope = process.env.VUE_APP_META_DATA_SCOPE as string;
    private appStoreUrl: string | undefined = process.env.VUE_APP_APPSTORE_UI_URL;
    private useCaseId: string | undefined = process.env.VUE_APP_USE_CASE_ID;

    private async mounted(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        await this.setProfileData();
        this.setNavItems();
        await this.loadApps();
    }

    private async setProfileData(): Promise<void> {
        const profile: any = await sdk?.localStorage.getPermissionsLocalStorage()
        if (profile) {
            this.user.name = profile.name;
            this.user.email = profile.username;
        }
    }

    private setNavItems(): void {
        this.navItems = this.$router.options.routes ? this.$router.options.routes.map(route => (
            {
                displayName: route.name,
                href: route.path,
                isActive: route.name === this.$router.currentRoute.name
            })
        ) : [];
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = (await this.appService.getApps()).result.items;
            this.applications = this.processApps(apps);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private logOut(): void {
        sdk?.auth.logout();
    }

    private sendFeedback(): void {
        window.open(`${this.appStoreUrl}/contact-page/${this.useCaseId}?isFeedback=true`, '_blank')
    }

    private reportIncident(): void {
        window.open(`${this.appStoreUrl}/incident-page/${this.useCaseId}`, '_blank')
    }

    private async copyToken(): Promise<void> {
        const textElement: any = document.getElementById('copyText');
        if (textElement) {
            const token = await sdk?.auth.getToken(this.scope);
            textElement.value = '';
            textElement.value = `Bearer ${token}`;
            textElement.select();
            document.execCommand('copy');
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToken');
        } else {
            throw new Error('Copy text element is undefined');
        }
    }

    get getAppDrawerLabels(): any {
        return { applications: i18n.t('appsWithAccess'), miscellaneous: i18n.t('appsWithoutAccess') };
    }

    private get isDevelopmentEnv(): boolean {
        return ['dev', 'development'].includes(process.env.VUE_APP_ENVIRONMENT as Environments);
    }
    private get isUatEnv(): boolean {
        return process.env.VUE_APP_ENVIRONMENT === 'uat';
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.app-logo {
    img {
        height: 90%;
        margin-right: 1rem;
        padding: 6px 6px 3px 6px;
    }
    .app-logo-img {
        height: 4.5rem;
    }
}

.profile-action-button {
    text-transform: none;
    width: 100%;
    border-radius: 0;
    margin-bottom: 2px;
    height: 3.6rem;

    &--important {
        border: 1px solid @contextual-red !important;
        color: @contextual-red !important;
        &:hover {
            color: @dark-red !important;
            border: 1px solid @dark-red !important;
        }
    }
}
.top-row-content {
    letter-spacing: 1px;
    .title {
        padding-left: 28px;
        color: white;
        text-decoration: none;
    }
    .market-area-label {
        padding-right: 28px;
        margin: 0;
    }
    .market-area-select {
        width: 100%;
        max-width: 260px;
        background: @dark-blue;
        border: none;
        padding: 9px 19px 8px 19px;
        color: white;
        border-radius: 4px;
        position: absolute;
        top: 1.5rem;
    }
}
.nav-links {
    .nav-link {
        color: #fff;
        font-size: 2rem;
        text-decoration: none;
        margin: 0 10px;
        vertical-align: middle;
    }
}
.profile-details {
    color: @dark-blue-grey;
    z-index: 10;
    font-size: 1.6rem;
    right: 0;
    .copy-token-button {
        width: 100%;
        color: @dark-blue-grey;
        transition: 0.2s ease-in;
        box-shadow: none;
        padding: 0;
        display: flex;
        align-items: center;
        :hover {
            transition: 0.2s ease-in;
        }
    }
    .swagger-link {
        color: @uniper-blue;
    }
}

.version {
    font-size: 1.3rem;
    margin: 0.3rem;
}

.copy-text {
    position: fixed;
    top: -99999rem;
}

::v-deep .adam-header__separator.adam-header-bar {
    background: white;
}
::v-deep .bg-dev {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-dev;
        .adam-header-user__icon.adam-header-bar path {
            fill: @uniper-dev;
        }
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-dev;
        }
        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-dev-darker;
        }
        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-dev-darker;
        }
    }
}
::v-deep .bg-uat {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-uat;
        .adam-header-user__icon.adam-header-bar path {
            fill:@uniper-uat;
        }
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-uat;
        }
        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-uat-darker;
        }
        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-uat-darker;
        }
    }
}
</style>
