<template>
    <div
        id="app"
        v-if="isAppReady"
    >
        <pui-application-layout>
            <template #header v-if="true">
                <header-wrapper />
            </template>

            <template #left v-if="false">
                <nav style="height: 100%;">
                    <pui-side-panel :open.sync="leftPanelIsOpen" title="Left" />
                </nav>
            </template>

            <template #main v-if="true">
                <main style="height: 100%; overflow: auto;">
                    <loader />
                    <router-view />
                </main>
            </template>

            <template #right v-if="false">
                <aside style="height: 100%;">
                    <pui-side-panel :open.sync="rightPanelIsOpen" title="Right" trigger-position="left" />
                </aside>
            </template>

            <template #footer v-if="false">
                <footer style="background: white;">
                    <p style="margin: 0; padding: 1rem">
                        Footer
                    </p>
                </footer>
            </template>
        </pui-application-layout>
        <snackbar />
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import { sdk } from '@/utils/sdk';
import './main.less';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        snackbar: Snackbar,
        loader: Loader,
    },
})
export default class App extends Vue {
    private isAppReady = false;
    private useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);

    private async mounted(): Promise<void> {
        await sdk?.core.userAccessManagement.setPermissions(this.useCaseId);
        this.isAppReady = true;
    }
}
</script>

<style lang="less">
</style>
